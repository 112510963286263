
#containerlegenda{
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    padding: 2px 0;
    gap: 12px;
    background: #eae7e6;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius  :2px;
}
