.SubTituloTabelaa {
    padding: 1px 8px;
    margin-top: 0.5gitrem;
    color: #828282;
    font-weight: 300;
    font-size: 1rem;
    border: 0;
    display: flex;
    align-content: center;
    justify-content: center;
}