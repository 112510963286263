.tituloTabelaa {
    padding: 1px 8px;
    margin-top: 1rem;
    color: #FC68A2;
    font-weight: 500;
    font-size: 1.5rem;
    border: 0;
    display: flex;
    align-content: center;
    justify-content: center;
}